import { format, parse } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Layout from '../../../components/Layout'
import SEO from '../../../components/SEO'
import './TermsOfService.scss'

const TermsOfService = () => {
  const {
    mdx: {
      body,
    },
    file: {
      fields: {
        gitLogLatestDate,
      },
    },
  } = useStaticQuery(graphql`
    query TOSQuery {
      mdx(fields: { title: { eq: "TOS" } } ) {
        body
      }
      file(name: { eq: "TOS" } ) {
        fields {
          gitLogLatestDate
        }
      }
    }
  `)

  const lastModified = parse(gitLogLatestDate, `yyyy-MM-dd HH:mm:ss XX`, new Date())

  return (
    <Layout>
      <SEO
        title="Terms of Service"
      />
      <div className="tos-page">
        <MDXRenderer>{body}</MDXRenderer>
        <div className="last-modified">
          <span>Last modified: {format(lastModified, `do LLLL yyyy`)}</span>
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfService